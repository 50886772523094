import queryString from 'query-string';
import { request, toSnakeCase } from '../../shared/request';

const DELETE = 'DELETE';

const baseName = () => (window.currentLocale.basename || '');

export const removeFavoriteApi = (id, type) => request(
  `${ baseName() }/my/favorites/${ id }?type=${ type }`, {
    method: DELETE
  }
);

export const addFavoriteApi = (id, type) => request(
  `${ baseName() }/my/favorites`,
  {
    method: 'POST',
    body: JSON.stringify(toSnakeCase({
      favoritableId: id,
      favoritableType: type
    }))
  }
);

export const scheduleProductView = (payload) => {
  if (window.mixpanelToken) {
    return request(`${ baseName() }/api/events`, {
      method: 'POST',
      body: JSON.stringify(toSnakeCase({ payload, event: 'productView', url: window.location.href }))
    }).then(() => {}).catch(() => {});
  }
  return Promise.resolve();
};

export const loadFavoritesApi = () => request(`${ baseName() }/my/favorites`);

export const loadCollectionsApi = (opts) => request(`${ baseName() }/collections?${ queryString.stringify(opts) }`);

export const loadCollectionDetailsApi = (id) => request(`${ baseName() }/collections/${ id }?complete_collection=true`);

export const loadOrderStatus = (id, zip, token) => request(`${ baseName() }/api/order_status`, {
  method: 'POST',
  body: JSON.stringify({ id, zip, token })
});

export const addToStoreItemsApi = (params) => request(`${ baseName() }/my/store_items`, {
  method: 'POST',
  body: JSON.stringify(toSnakeCase(params))
});

export const removeFromStoreItemsApi = (storeItemId) => request(`${ baseName() }/my/store_items/${ storeItemId }`, { method: DELETE });

export const loadOriginalFiltersApi = () => request(`${ baseName() }/api/original_arts/filters`);

export const loadArtistFiltersApi = () => request(`${ baseName() }/api/artists/filters`);

export const loadOriginalArtsApi = (page, params) => request(`${ baseName() }/api/original_arts?${ queryString.stringify({ ...params, api_page: page }) }`);

export const loadAuthorOriginalArtApi = (id) => request(`${ baseName() }/api/original_arts?${ queryString.stringify({ api_page: -1, of_user: id }) }`);

export const loadArtistsApi = (page, params) => request(`${ baseName() }/api/artists?${ queryString.stringify({ ...params, api_page: page }) }`);

export const loadOriginalArtApi = (id) => request(`${ baseName() }/api/original_arts/${ id }`);

export const getGiftCardBalanceApi = (cardNumber, pinCode) => request(
  `${ baseName() }/gift_cards`,
  {
    method: 'POST',
    body: JSON.stringify(toSnakeCase({
      cardNumber,
      pinCode
    }))
  }
);

export const loadWallFiltersApi = () => request(`${ baseName() }/api/walls2/filters`);

export const loadWallsApi = (page, params) => request(`${ baseName() }/api/walls2?${ queryString.stringify({ ...params, api_page: page }) }`);

export const loadWallApi = (id, sharingToken) => (
  id ? request(`${ baseName() }/api/walls2/${ id }`) : request(`${ baseName() }/api/walls2/${ sharingToken }?sharing=true`)
);

export const loadMyWallsApi = (page) => request(`${ baseName() }/api/my/walls?${ queryString.stringify({ page }) }`);

export const deleteMyWallApi = (id) => request(`${ baseName() }/api/my/walls/${ id }`, { method: DELETE });

export const loadWorksPageApi = (params) => request(`${ baseName() }/api/arts?${ queryString.stringify(params) }`, { withHeaders: true  });

export const loadTapestriesPageApi = (params) => request(`${ baseName() }/api/tapestries?${ queryString.stringify(params) }`, { withHeaders: true  });

export const loadWork = (id) => request(`${ baseName() }/api/arts/${ id }?with_recent=true`);

export const fetchArtFiltersApi = () => request(`${ baseName() }/api/arts/filters`);

export const loadAdditionalWorks = (id, limit = null) => {
  const extraPath = limit ? `&limit=${ limit }` : '';
  return request(`${ baseName() }/other_works/additional?source_id=${ id }${ extraPath }`);
};

export const loadRecentWorks = (ids) => request(`${ baseName() }/other_works/recent?ids=${ ids }`);

export const getArtistWorks = (id) => request(`${ baseName() }/api/arts?of_user=${ id }`);

export const getArtistInfo = (id) => request(`${ baseName() }/api/artists/${ id }`);

export const subscribeGuest = (email) => request(
  `${ baseName() }/api/guest_user`, {
    method: 'PUT',
    body: JSON.stringify({
      email
    })
  }
);

const asyncLoadScript = (scriptURL, condition) => new Promise((resolve, reject) => {
  if (condition) {
    resolve();
    return;
  }
  const script = document.createElement('script');
  script.src = scriptURL;
  script.async = true;
  script.onload = () => resolve();
  script.onerror = (error) => reject(error);
  document.body.appendChild(script);
});

export const executeRecaptcha = (action) => new Promise((resolve, reject) => {
  asyncLoadScript(`https://www.google.com/recaptcha/enterprise.js?render=${ window.captchaSiteKey }`, window.grecaptcha).then(() => {
    window.grecaptcha.enterprise.ready(() => {
      window.grecaptcha.enterprise.execute(
        window.captchaSiteKey, { action }
      ).then((token) => resolve(token)).catch((error) => reject(error));
    });
  }).catch(() => {});
});

export const subscribeToNewsletterApi = (email, captcha) => request(
  `${ baseName() }/subscriptions`, {
    method: 'POST',
    body: JSON.stringify({
      email, captcha
    })
  }
);

export const getCartItems = (orderId) => request(orderId ? `${ baseName() }/api/cart_items/?order_id=${ orderId }` : `${ baseName() }/api/cart_items`);

export const deleteCartItem = (id) => request(`${ baseName() }/api/cart_items/${ id }`, { method: DELETE });

export const loadAddressApi = (billing) => request(`${ baseName() }/api/address?billing=${ !!billing }`);

export const updateAddressApi = (object) => request(`${ baseName() }/api/address`, {
  method: 'PUT',
  body: JSON.stringify(toSnakeCase(object))
});

export const createGuestUserApi = ({ email, subscribe }) => request(`${ baseName() }/api/guest_user`, {
  method: 'POST',
  body: JSON.stringify({ email, subscribe })
});

export const associatePaypalOrderApi = (orderId, paypalOrderId) => request(`${ baseName() }/api/paypal_order`, {
  method: 'POST',
  body: JSON.stringify(toSnakeCase({ orderId, paypalOrderId }))
});

export const placeOrderApi = (paymentMethod, orderId, unsubscribeUser) => request(
  `${ baseName() }/orders/${ orderId }/payment`,
  {
    method: 'PUT',
    body: JSON.stringify(toSnakeCase({ paymentMethod, source: 'desktop', unsubscribeUser: (unsubscribeUser ? 'true' : false) }))
  }
);

export const createAppleWalletApi = (orderId, paymentId, validationURL) => request(`${ baseName() }/api/orders/${ orderId }/apple_wallets?payment_id=${ paymentId }`, {
  method: 'POST',
  body: JSON.stringify(toSnakeCase({
    validationURL
  }))
});

export const completeApplePaymentApi = (orderId, paymentId, applePaymentToken) => request(`${ baseName() }/api/orders/${ orderId }/apple_wallets?payment_id=${ paymentId }`, {
  method: 'PUT',
  body: JSON.stringify(toSnakeCase({
    applePaymentToken
  }))
});

export const loadInfoPage = (id) => request(`${ baseName() }/api/static_pages/${ id }`);

export const addToCartGenericApi = (opts) => request(`${ baseName() }/cart_items?api2=true`, {
  method: 'POST',
  body: JSON.stringify(toSnakeCase(opts))
});

export const addToCartApi = (cartItem) => request(`${ baseName() }/cart_items?api2=true`, {
  method: 'POST',
  body: JSON.stringify(toSnakeCase({ cartItem }))
});

export const getArtist = (id) => request(`${ baseName() }/api/artists/${ id }?with_works=true`);

export const getProducts = () => request(`${ baseName() }/api/products`);

export const getProduct = (id) => request(`${ baseName() }/api/products/${ id }`);

export const getCurrentReferralApi = () => request('/api/referral');

export const getRewardedPromoCode = (page) => request(`/api/referral_rewards?page=${ page }`);

export const createReferralApi = ({ email, fullName }) => request(
  '/api/referral',
  {
    method: 'POST',
    body: JSON.stringify(toSnakeCase({ email, fullName }))
  }
);

export const getExploredArtApi = (query) => request(`${ baseName() }/api/explore?squery=${ query || '' }`);

export const getWallShufflerMatches = (workSizeId, frameId) => request(`${ baseName() }/api/wall_shuffler_match?work_size_id=${ workSizeId }&frame_id=${ frameId }`);
