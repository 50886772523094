import { toCamelCase, toSnakeCase } from '../wall_builder/config/utils';

const defaultHeaders = () => {
  const token = document.querySelector('meta[name=csrf-token]');
  return ({
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': token ? token.getAttribute('content') : null
    }
  });
};

const request = (url, opts = {}) => new Promise((resolve, reject) => {
  const { withHeaders, ...otherOpts } = opts;
  window.fetch(url, {
    ...otherOpts,
    ...defaultHeaders(),
    credentials: 'include'
  }).then((response) => {
    if (response.ok) {
      response.json().then((responseJson) => {
        const data = toCamelCase(responseJson);
        if (withHeaders) {
          resolve([data, response.headers]);
        } else {
          resolve(data);
        }
      }).catch((error) => reject(error));
      return;
    }
    reject(response);
  }).catch((error) => {
    reject(error);
  });
});

const searchTagsWithOptions = (query, basePath) => new Promise((resolve) => {
  const path = `${ basePath }?q=${ query }&_=${ (new Date()).getTime() }`;
  request(path).then((options) => {
    const res = options.map((o) => ({ value: o.id, label: o.name }));
    resolve(res);
  });
});

export {
  toCamelCase, toSnakeCase, request, searchTagsWithOptions
};
